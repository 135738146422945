@charset "UTF-8";


body {
  font-family: "Noto Sans Japanese", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "Helvetica Neue", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: 14px;
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;
  min-width: 1200px;
}
@media all and (-ms-high-contrast:none){
  /* IE10+ only */
}
ul,ol {
  list-style-type: none;
}
p {
}
a {
  color: inherit;
  text-decoration: none;
}
img {
  max-width: 100%;
}

.pc {
  display: block;
}
.sp {
  display: none;
}
input[type="submit"] {
  -webkit-appearance: none;
}
input[type="text"],input[type="email"],input[type="tel"],textarea {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
}
.js-fadein {
  opacity: 0;
  top: 0;
  position: relative;
  transition: top 0.7s ease-out;
}
.js-fadein-active {
  top: -15px;
  transition: top 0.7s ease-out;
}
// page top button
.page_top_btn {
  display: block;
  opacity: 0;
  width: 70px;
  height: 50px;
  border: 1px solid #000;
  border-radius: 5px;
  background: rgba(0,0,0,0.8);
  position: fixed;
  bottom: 10px;
  right: 30px;
  transition: .2s;
  &:before {
    content: '';
    display: block;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg);
    width: 15px;
    height: 15px;
    position: relative;
    top: 18px;
    left: 25px;
    transition: .2s;
  }
  &:hover {
    background: rgba(255,255,255,1);
    transition: .2s;
    &:before {
      border-color: #000;
    }
  }
}

@media screen and (max-width:460px){
  body {
    min-width: initial;
  }

  .page_top_btn {
    height: 30px;
    width: 50px;
    &:before {
      width: 9px;
      height: 9px;
      top: 10px;
      left: 19px;
    }
  }
}




